import { Overrides } from '@material-ui/core/styles/overrides';
import pallete from '../palette';

const MuiInputLabel: Overrides['MuiFormLabel'] = {
  root: {
    color: pallete.primary.main,
    '&:focus': {
      color: pallete.primary.dark,
    },
    '&:hover': {
      color: pallete.primary.dark,
    },
  },
  colorSecondary: {
    color: pallete.secondary.main,
  },
};

export default MuiInputLabel;
