import React from 'react';
import { Typography } from '@material-ui/core';
import pkg from '../../../package.json';
const About: React.FC = () => (
  <Typography variant="h3" color="primary">
    {' '}
    Version: {pkg?.version}{' '}
  </Typography>
);

export default About;
