import { createSlice } from '@reduxjs/toolkit';
import { IAlertHandlerState } from './interfaces';

const initialState: IAlertHandlerState = {
  open: false,
  title: '',
  msg: '',
  cb: undefined,
};

const alertHandlerSlice = createSlice({
  name: 'alertHandler',
  initialState,
  reducers: {
    togleAlert(state, { payload }) {
      state = payload;
      return state;
    },
    clearAlert(state) {
      state = initialState;
      return state;
    },
  },
});

const { reducer, actions } = alertHandlerSlice;
export const { togleAlert, clearAlert } = actions;
export default reducer;
