import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { post } from 'api';
import { REQUEST_LIFE_CICLE, IMyKnownError } from 'interfaces/requests';
import { AppDispatch } from 'store';
import { AxiosError, AxiosResponse } from 'axios';
import ReactGA from 'react-ga';
import { INewLead, INewLeadState } from './interfaces';
import { clearNewLeadFields } from './helpers';
import endpoints from './endpoints';
import { togleAlert } from 'modules/alertHandler';

export const newLeadAction = createAsyncThunk<
  void,
  INewLead,
  {
    dispatch: AppDispatch;
    rejectValue: IMyKnownError;
  }
>('newLeadAction', async (payload, { dispatch, rejectWithValue }) => {
  payload.setSubmitting(true);
  try {
    const result: AxiosResponse = await post({
      url: endpoints.newLead(),
      data: clearNewLeadFields(payload),
    });
    dispatch(
      togleAlert({
        open: true,
        title: 'Formulário enviado com sucesso',
        msg: `Segue sua nova chave ${result.data}`,
      }),
    );
    payload.resetForm();
    ReactGA.event({
      category: 'NewLeadRegister',
      action: payload.name,
    });
  } catch (err) {
    const error = err as AxiosError<IMyKnownError>;
    if (error.response?.data.message) {
      dispatch(
        togleAlert({
          open: true,
          title: 'Lamentamos, mas algo deu errado.',
          msg: error.response.data.message,
        }),
      );
    } else {
      dispatch(
        togleAlert({
          open: true,
          title: 'Lamentamos, mas algo deu errado.',
          msg: `Por favor, tente enviar novamente. Se o erro persistir,
          entre em contato com nossa equipe pelo e‑mail legado@rogatis.com.br.
          Estamos à disposição para ajudá-lo com isso.`,
          errMsg: err,
        }),
      );
    }
    ReactGA.exception({
      category: 'NewLeadRegister',
      action: 'fail new lead',
    });
    return rejectWithValue(error.response?.data as IMyKnownError);
  } finally {
    payload.setSubmitting(false);
  }
});

const initialState = {
  loading: REQUEST_LIFE_CICLE.IDLE,
} as INewLeadState;

const NewLeadSlice = createSlice({
  name: 'newLead',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(newLeadAction.pending, (state) => {
      state.loading = REQUEST_LIFE_CICLE.PENDING;
      return state;
    });
    builder.addCase(newLeadAction.rejected, (state) => {
      state.loading = REQUEST_LIFE_CICLE.FAILED;
      return state;
    });
  },
});

const { reducer } = NewLeadSlice;
export default reducer;
