import { parseISO, format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { ILeadResponse } from './interfaces';
export const dataTransform = ({
  Leads,
}: {
  Leads: ILeadResponse[];
}): ILeadResponse[] => {
  Leads.forEach((item: ILeadResponse, index: number) => {
    item.id = index;
    item.AccessAmount = item.passcodeCounter.length;
    item.LastAccessDate =
      item.passcodeCounter.length > 0
        ? format(
            zonedTimeToUtc(
              parseISO(item.passcodeCounter[item.passcodeCounter.length - 1]),
              'America/Sao_Paulo',
            ),
            'dd/MM/yyyy HH:mm:ss',
          )
        : null;
  });
  return Leads;
};
