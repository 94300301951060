import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import About from 'pages/About';
import withTracker from './WithTracker';
import Loading from 'components/loading';
import { SEC_LEVELS } from 'modules/secLevel/interfaces';

const Login = lazy(() => import('pages/Login'));
const LeadLogin = lazy(() => import('pages/LeadLogin'));
const NotExists = lazy(() => import('pages/404'));
const AdminDashboard = lazy(() => import('containers/AdminDashboard'));
const LeadHome = lazy(() => import('containers/LeadHome'));
const RmHome = lazy(() => import('containers/RmHome'));
const Calendar = lazy(() => import('pages/Calendar'));

interface ProtectedRouteProps {
  component: React.FC<RouteComponentProps>;
  pageLevel: number;
  path: string;
  exact?: boolean;
}

const PrivateRoute = ({
  component: Component,
  path,
  exact = false,
  pageLevel,
}: ProtectedRouteProps): JSX.Element => {
  const secLevel = useSelector((state: RootState) => state.secLevel.secLevel);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => {
        if (
          [SEC_LEVELS.ADMIN, SEC_LEVELS.RM].includes(secLevel) ||
          pageLevel === secLevel
        )
          return <Component {...props} />;
        if (secLevel === SEC_LEVELS.LEAD)
          return <Redirect to={{ pathname: '/home' }} {...props} />;
        return <Redirect to={{ pathname: '/' }} />;
      }}
    />
  );
};

interface IRouterProps {
  history: History;
}

const Router: React.FC<IRouterProps> = ({ history }: IRouterProps) => {
  return (
    <Suspense fallback={<Loading />}>
      <ConnectedRouter history={history}>
        <Switch>
          <PrivateRoute
            path="/home"
            component={withTracker(LeadHome)}
            pageLevel={2}
          />
          <PrivateRoute
            path="/rmHome"
            component={withTracker(RmHome)}
            pageLevel={3}
          />
          <PrivateRoute
            path="/AdminDashboard"
            component={withTracker(AdminDashboard)}
            pageLevel={4}
          />
          <Route exact path="/Y2FsZW5kYXI=" component={withTracker(Calendar)} />
          <Route exact path="/about" component={withTracker(About)} />
          <Route exact path="/admin" component={withTracker(Login)} />
          <Route exact path="/" component={withTracker(LeadLogin)} />
          <Route path="*" component={NotExists} />
        </Switch>
      </ConnectedRouter>
    </Suspense>
  );
};

export default Router;
