import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    Root: {
      width: '100%',
      height: '100%',
      color: '#4d4d4d',
      boxSizing: 'border-box',
      fontSize: '1.6em',
    },
  }),
);
