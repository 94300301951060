import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID || '', {
  debug: process.env.REACT_APP_ANALYTICS_DEBUG?.toString() === 'true' || false,
  titleCase: false,
  gaOptions: {
    userId: '123',
  },
});

ReactDOM.render(<App />, document.getElementById('root'));
