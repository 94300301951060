import { IRegisterFormObject } from 'interfaces/registerForm';
import { IPostRequestData } from './interfaces';

const paymentEnum = [
  '',
  'oneOfTheAttendees',
  'company',
  'otherPerson',
] as const;

const roleDefinition = (
  valuesPrefix: string,
  paymentResponsableType: number,
) => {
  const role =
    valuesPrefix === 'payingResponsable' && paymentResponsableType === 2
      ? 'companyInCharge'
      : valuesPrefix === 'payingResponsable'
      ? 'personInCharge'
      : 'attendee';
  return role;
};

export const createPostRequest = (
  formikValues: IRegisterFormObject,
  leadCode: string,
  paymentResponsableType: number,
): IPostRequestData => {
  const arrayOfKeys = Object.keys(formikValues);
  const postBody = arrayOfKeys.map((valuesPrefix) => {
    return {
      role: roleDefinition(valuesPrefix, paymentResponsableType),
      fullName: formikValues[valuesPrefix].fullName,
      email: formikValues[valuesPrefix].email,
      birthDate: formikValues[valuesPrefix].dateOfBirth,
      countryAreaCode: formikValues[valuesPrefix].countryAreaCode,
      areaCode: formikValues[valuesPrefix].areaCode,
      phone: formikValues[valuesPrefix].phone,
      address: {
        place: formikValues[valuesPrefix].place,
        placeComplement: formikValues[valuesPrefix].addressComplement,
        number: formikValues[valuesPrefix].addressNumber,
        city: formikValues[valuesPrefix].city,
        state: formikValues[valuesPrefix].state,
        country: formikValues[valuesPrefix].country,
        zipCode: formikValues[valuesPrefix].zipCode,
      },
      cpf: formikValues[valuesPrefix].cpf,
      rg: formikValues[valuesPrefix].rg,
      passport: formikValues[valuesPrefix].passport,
      companyName: formikValues[valuesPrefix].companyName,
      cnpj: formikValues[valuesPrefix].cnpj,
      countryOfHeadOfficeDomicile:
        formikValues[valuesPrefix].countryOfHeadOfficeDomicile,
      countryOfCitizenship: formikValues[valuesPrefix].countryOfCitizenship,
      companyDocument: formikValues[valuesPrefix].companyDocument,
    };
  });

  if (paymentResponsableType === 1) {
    const fistUser = Object.assign({}, postBody[0]);
    fistUser.role = 'personInCharge';
    postBody.push(fistUser);
  }

  const result = {
    leadCode: leadCode.trim(),
    paymentMethods:
      formikValues[arrayOfKeys[arrayOfKeys.length - 1]].paymentMethods,
    selectedEntityForPayment: paymentEnum[paymentResponsableType],
    contractComponents: postBody,
  };
  return result;
};

export const clearEmptyFields = (
  payload: IPostRequestData,
): IPostRequestData => {
  const ccClear = payload.contractComponents.map((item) => {
    if (item.role === 'companyInCharge') {
      delete item.birthDate;
    }
    const itemToReturn = Object.assign({}, item);

    Object.keys(item).forEach((key: string) => {
      const typedKey = key as keyof typeof item;
      if (item[typedKey] === '') {
        delete itemToReturn[typedKey];
      }
    });
    Object.keys(item.address).forEach((key: string) => {
      const typedKey = key as keyof typeof item.address;
      if (item.address[typedKey] === '') {
        delete itemToReturn.address[typedKey];
      }
    });
    return itemToReturn;
  });
  payload.contractComponents = ccClear;
  return payload;
};
