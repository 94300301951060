import { createSlice } from '@reduxjs/toolkit';
import { ISecLevel, SEC_LEVELS } from './interfaces';

const initialState: ISecLevel = {
  secLevel: SEC_LEVELS.NONE,
};

const secLevelSlice = createSlice({
  name: 'secLevel',
  initialState,
  reducers: {
    resetSecLevelAction(state) {
      state.secLevel = initialState.secLevel;
      return state;
    },
    setSecLevelAction(state, { payload }: { payload: SEC_LEVELS }) {
      state.secLevel = payload;
      return state;
    },
  },
});

const { reducer, actions } = secLevelSlice;
export const { resetSecLevelAction, setSecLevelAction } = actions;
export default reducer;
