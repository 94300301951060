import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_LIFE_CICLE, IMyKnownError } from 'interfaces/requests';
import endpoints from './endpoints';
import { get } from 'api';
import { IContractList } from './interfaces';
import { iContract } from 'interfaces/contracts';
import { dataTransform } from './helpers';

const initialState = {
  loading: REQUEST_LIFE_CICLE.IDLE,
  list: [],
} as IContractList;

export const contractReportAction = createAsyncThunk<
  iContract[],
  undefined,
  { rejectValue: IMyKnownError }
>('contractReport/fetch', async (payload, { rejectWithValue }) => {
  try {
    const result = await get<iContract[]>({
      url: endpoints.listContracts(),
    });
    const tranform = dataTransform({ Contracts: result.data });
    return tranform;
  } catch (err) {
    return rejectWithValue((await err.json()) as IMyKnownError);
  }
});

const ContractsReportSlice = createSlice({
  name: 'contractsReport',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(contractReportAction.fulfilled, (state, { payload }) => {
      state.loading = REQUEST_LIFE_CICLE.SUCCEEDED;
      state.list = [...payload];
      return state;
    });
    builder.addCase(contractReportAction.pending, (state) => {
      state.loading = REQUEST_LIFE_CICLE.PENDING;
      state.list = initialState.list;
      return state;
    });
    builder.addCase(contractReportAction.rejected, (state) => {
      state.loading = REQUEST_LIFE_CICLE.FAILED;
      state.list = initialState.list;
      return state;
    });
  },
});

const { reducer } = ContractsReportSlice;
export default reducer;
