import { Overrides } from '@material-ui/core/styles/overrides';

import pallete from '../palette';
const MuiOutlinedInput: Overrides['MuiOutlinedInput'] = {
  notchedOutline: {
    borderColor: 'inherit',
  },
  colorSecondary: {
    color: pallete.secondary.main,
  },
  root: {
    borderRadius: 0,
    color: pallete.primary.main,
  },
};

export default MuiOutlinedInput;
