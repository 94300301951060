import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IUsersListState, IUserListItem } from './interfaces';
import { REQUEST_LIFE_CICLE, IMyKnownError } from 'interfaces/requests';
import endpoints from './endpoints';
import { get } from 'api';

export const UsersListGetAction = createAsyncThunk<
  IUserListItem[],
  undefined,
  { rejectValue: IMyKnownError }
>('usersList/fetch', async (payload, { rejectWithValue }) => {
  try {
    const result = await get<IUserListItem[]>({
      url: endpoints.listUsers(),
    });
    return result.data as IUserListItem[];
  } catch (err) {
    return rejectWithValue((await err.json()) as IMyKnownError);
  }
});

const initialState = {
  loading: REQUEST_LIFE_CICLE.IDLE,
  usersList: [
    {
      _id: '',
      role: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      createdAt: '',
      updatedAt: '',
    },
  ],
} as IUsersListState;

const UsersListSlice = createSlice({
  name: 'usersList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UsersListGetAction.fulfilled, (state, { payload }) => {
      state.loading = REQUEST_LIFE_CICLE.SUCCEEDED;
      state.usersList = [...payload];
      return state;
    });
    builder.addCase(UsersListGetAction.pending, (state) => {
      state.loading = REQUEST_LIFE_CICLE.PENDING;
      state.usersList = initialState.usersList;
      return state;
    });
    builder.addCase(UsersListGetAction.rejected, (state) => {
      state.loading = REQUEST_LIFE_CICLE.FAILED;
      state.usersList = initialState.usersList;
      return state;
    });
  },
});

const { reducer } = UsersListSlice;
export default reducer;
