import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const WithTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  const HOC = (prop) => {
    useEffect(() => trackPage(prop.location.pathname), [
      prop.location.pathname,
    ]);

    return <WrappedComponent {...prop} />;
  };

  return HOC;
};

export default WithTracker;
