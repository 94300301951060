import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { post } from 'api';
import { REQUEST_LIFE_CICLE, IMyKnownError } from 'interfaces/requests';
import { AppDispatch } from 'store';
import ReactGA from 'react-ga';
import {
  IRegisterFormInitialValues,
  IRegisterFormObjectPayload,
} from './interfaces';
import { clearEmptyFields, createPostRequest } from './helpers';
import endpoints from './endpoints';
import { togleAlert } from 'modules/alertHandler';
import { store, RootState } from 'store';
import { setSelectedLeadNavigationAcion } from 'modules/LeadNavigation';
import { LEAD_FORM_NAV } from 'interfaces';

export const NewContractAction = createAsyncThunk<
  void,
  IRegisterFormObjectPayload,
  {
    dispatch: AppDispatch;
    rejectValue: IMyKnownError;
  }
>(
  'newContractAction/fetching',
  async ({ values, setSubmitting }, { dispatch, rejectWithValue }) => {
    const state: RootState = store.getState();
    setSubmitting(true);
    try {
      await post({
        url: endpoints.contracts(),
        data: clearEmptyFields(
          createPostRequest(
            values,
            state.lead.leadCode,
            state.registerForm.paymentResponsable,
          ),
        ),
      });
      setSubmitting(false);
      dispatch(
        setSelectedLeadNavigationAcion(LEAD_FORM_NAV.LeadFormConfirmation),
      );
    } catch (err) {
      setSubmitting(false);
      dispatch(
        togleAlert({
          open: true,
          title: 'Lamentamos, mas algo deu errado.',
          msg: `Por favor, tente enviar novamente. Se o erro persistir, entre em contato com nossa equipe pelo e‑mail legado@rogatis.com.br Estamos à disposição para ajudá-lo com isso.`,
          //serrMsg: err,
        }),
      );
      return rejectWithValue((await err.json()) as IMyKnownError);
    }
  },
);

const initialState = {
  amount: 0,
  paymentResponsable: 0,
  loading: REQUEST_LIFE_CICLE.IDLE,
} as IRegisterFormInitialValues;

const RegisterFormSlice = createSlice({
  name: 'postContract',
  initialState,
  reducers: {
    setFormPaymentRespAction(state, action) {
      const leadCode = action.payload.leadCode;
      state.paymentResponsable = action.payload.value;
      ReactGA.event({
        category: 'click at payment resp selector',
        action: leadCode,
      });
    },
    setFormAmountAction(state, action) {
      const leadCode = action.payload.leadCode;
      state.amount = action.payload.value;
      ReactGA.event({
        category: 'click at quant selector',
        action: leadCode,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(NewContractAction.pending, (state) => {
      state.loading = REQUEST_LIFE_CICLE.PENDING;
      return state;
    });
    builder.addCase(NewContractAction.rejected, (state) => {
      state.loading = REQUEST_LIFE_CICLE.FAILED;
      return state;
    });
  },
});

const { reducer, actions } = RegisterFormSlice;
export const { setFormAmountAction, setFormPaymentRespAction } = actions;
export default reducer;
