import { IDecodedUser } from 'interfaces';
import jwt_decode from 'jwt-decode';

export const capitalizeString = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const decodeToken = (token: string): IDecodedUser => {
  const decoded: IDecodedUser = jwt_decode(token);
  return decoded;
};
