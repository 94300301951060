export enum REQUEST_LIFE_CICLE {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export interface IMyKnownError {
  message: string;
}
