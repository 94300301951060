import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { post } from 'api';
import { REQUEST_LIFE_CICLE, IMyKnownError } from 'interfaces/requests';
import { AppDispatch } from 'store';
import { AxiosResponse } from 'axios';
import { clearAlert } from '../alertHandler';
import ReactGA from 'react-ga';
import {
  IUserAxiosReponse,
  IUserLoginActionResponse,
  IUserLoginRequestPayload,
  IUserLogoutPayload,
  IUserState,
} from './interfaces';
import { decodeToken } from 'utils/helpers';
import { setSecLevelAction } from 'modules/secLevel';
import { clearLeadDataAction } from 'modules/Lead';
import endpoints from './endpoints';
import { IDecodedUser, USER_ROLES } from 'interfaces';

const initialState = {
  userId: '',
  token: '',
  refreshToken: '',
  errorMsg: '',
  firstName: '',
  lastName: '',
  role: '',
  loading: REQUEST_LIFE_CICLE.IDLE,
} as IUserState;

export const userLogoutAction = createAsyncThunk<
  void,
  IUserLogoutPayload,
  {
    dispatch: AppDispatch;
  }
>('userLogoutAction', async ({ history }, { dispatch }) => {
  dispatch(userClearAction());
  if (history) {
    history.push('/admin');
  }
});

export const userClearAction = createAsyncThunk<
  void,
  undefined,
  {
    dispatch: AppDispatch;
  }
>('userClearAction', async (payload, { dispatch }) => {
  sessionStorage.clear();
  dispatch(clearAlert());
});

export const userLoginAction = createAsyncThunk<
  IUserLoginActionResponse,
  IUserLoginRequestPayload,
  {
    dispatch: AppDispatch;
    rejectValue: IMyKnownError;
  }
>(
  'userLoginAction/fetch',
  async (
    {
      email,
      password,
      setSubmitting,
      setFieldError,
      errField,
      errMsg,
      history,
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      await dispatch(clearLeadDataAction());
      const response: AxiosResponse<IUserAxiosReponse> = await post({
        url: endpoints.login(),
        data: {
          email,
          password,
        },
      });

      const user: IDecodedUser = decodeToken(response.data.token);
      if (user.role === USER_ROLES.MANAGER) {
        dispatch(setSecLevelAction(3));
        if (history) {
          history.push('/AdminDashboard');
        }
      }
      if (user.role === USER_ROLES.ADMIN) {
        dispatch(setSecLevelAction(4));
        if (history) {
          history.push('/AdminDashboard');
        }
      }
      const result = {
        token: response.data.token,
        refreshToken: response.data.refreshToken,
        ...user,
      };

      return result;
    } catch (err) {
      setFieldError(errField, errMsg);
      if (history) {
        dispatch(userLogoutAction({ history }));
      }
      return rejectWithValue((await err.json()) as IMyKnownError);
    } finally {
      setSubmitting(false);
    }
  },
);

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userLoginAction.fulfilled, (state, { payload }) => {
      ReactGA.event({
        category: 'login',
        action: payload.email,
      });
      state.userId = payload.userId;
      state.token = payload.token;
      state.refreshToken = payload.refreshToken;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.role = payload.role;
      state.loading = REQUEST_LIFE_CICLE.SUCCEEDED;

      return state;
    });
    builder.addCase(userLoginAction.pending, (state) => {
      state.loading = REQUEST_LIFE_CICLE.PENDING;
    });
    builder.addCase(userLoginAction.rejected, (state) => {
      state.loading = REQUEST_LIFE_CICLE.IDLE;
    });
  },
});

const { reducer } = UserSlice;
export default reducer;
