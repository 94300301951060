import createPalette, {
  PaletteOptions,
} from '@material-ui/core/styles/createPalette';

const paletteOverrides: PaletteOptions = {
  common: {
    black: '#000',
    white: '#fff',
  },
  type: 'light',
  primary: {
    light: '#0990EF',
    main: '#19A0FF',
    dark: '#121F82',
    contrastText: '#080e40',
  },
  secondary: {
    light: '#ff4081',
    main: '#FFF',
    dark: '#9e9e9e',
    contrastText: '#7B9D8D',
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  grey: {
    '50': '#fafafa',
    '100': '#F7F8F7',
    '200': '#eeeeee',
    '300': '#e0e0e0',
    '400': '#bdbdbd',
    '500': '#9e9e9e',
    '600': '#757575',
    '700': '#5e5e5e',
    '800': '#424242',
    '900': '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    default: '#fff',
    paper: '#fafafa',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(0, 0, 0, 0.14)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledOpacity: 0.38,
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
  },
};

const defaultPallete = createPalette(paletteOverrides);
export default defaultPallete;
