import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { history, store, persistor } from 'store';
import Router from 'router';
import customTheme from 'theme';
import Loading from 'components/loading';
import { hotjar } from 'react-hotjar';

if (process.env.REACT_APP_HOTJAR_ID) {
  hotjar.initialize(parseInt(process.env.REACT_APP_HOTJAR_ID, 10), 1);
}

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <ThemeProvider theme={customTheme}>
          <CssBaseline />
          <Suspense fallback={<Loading />}>
            <Router history={history} />
          </Suspense>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
