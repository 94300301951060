import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
} from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storageSession from 'redux-persist/lib/storage/session';
import thunkMiddleware from 'redux-thunk';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import reducers from 'modules';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createRootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
});

export type RootState = ReturnType<typeof createRootReducer>;

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_ENCRYPTOR_SECRET_KEY || '',
  onError: (error) => {
    console.error(error);
  },
});

const persistConfig = {
  key: 'root',
  storage: storageSession,
  stateReconciler: autoMergeLevel2,
  blacklist: [],
  transforms: [encryptor],
};

const pReducer = persistReducer<RootState>(persistConfig, createRootReducer);

const middlewares = [routeMiddleware, thunkMiddleware];

const store = configureStore({
  reducer: pReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER,
        'OPEN_ALERT_HANDLER',
      ],
    },
  }).prepend(...middlewares),
});

const persistor = persistStore(store);

if (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Unreachable code error
  module.hot &&
  process.env.NODE_ENV !== 'production' &&
  process.env.NODE_ENV !== 'test'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const newRootReducer = require('../modules').reducers;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Unreachable code error
  module.hot.accept('../modules', () => store.replaceReducer(newRootReducer));
}

export type AppDispatch = typeof store.dispatch;

export { store, history, persistor };
