import { INewLead } from './interfaces';

export const clearNewLeadFields = (payload: INewLead): INewLead => {
  const itemToReturn = Object.assign({}, payload);

  Object.keys(payload).forEach((key) => {
    const typedKey = key as keyof typeof itemToReturn;
    if (payload[typedKey] === '' || payload[typedKey] == null) {
      delete itemToReturn[typedKey];
    }
  });

  return itemToReturn;
};
