import alertHandler from 'modules/alertHandler';
import contractReports from 'modules/ContractReports';
import lead from 'modules/Lead';
import leadNavigation from 'modules/LeadNavigation';
import leadReport from 'modules/LeadReport';
import newLead from 'modules/NewLead';
import newUser from 'modules/NewUser';
import registerForm from 'modules/RegisterForm';
import secLevel from 'modules/secLevel';
import user from 'modules/User';
import userList from 'modules/UsersList';

const rootReducer = {
  alertHandler,
  contractReports,
  lead,
  leadNavigation,
  leadReport,
  newLead,
  newUser,
  registerForm,
  secLevel,
  user,
  userList,
};

export default rootReducer;
