import { Overrides } from '@material-ui/core/styles/overrides';
import pallete from '../palette';

const MuiButton: Overrides['MuiButton'] = {
  root: {
    borderRadius: 0,
  },
  sizeLarge: {
    width: '280px',
  },

  textSecondary: {
    backgroundColor: pallete.secondary.main,
    color: pallete.primary.main,
    '&:hover': {
      backgroundColor: pallete.secondary.dark,
      color: pallete.secondary.main,
    },
  },
  outlined: {
    border: `1px solid ${pallete.primary.main}`,
    color: pallete.primary.main,
  },
  contained: {
    backgroundColor: pallete.primary.main,
    color: pallete.common.white,
    '&:hover': {
      backgroundColor: pallete.primary.dark,
    },
  },
};

export default MuiButton;
