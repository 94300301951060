import { IRegisterFormObject } from './registerForm';

export interface SelectItem {
  value: string | number;
  label: string;
}

export interface Cep {
  logradouro: string;
  bairro: string;
  localidade: string;
  uf: string;
  complemento: string;
  erro: boolean;
}

export interface RegisterFormProps {
  isSubmitting: boolean;
  numberOfGuests: number;
  paymentResponsableType: number;
  values: IRegisterFormObject;
}

export interface IFormikSelectProps {
  name: string;
  items: SelectItem[];
  label: string;
  required?: boolean;
  onClose?: () => void;
}

export enum LEAD_FORM_NAV {
  home,
  form,
  LeadFormConfirmation,
}

export interface IButtonList {
  label: string;
  href: string;
  onlyLead: boolean;
  onlyRm: boolean;
}

export enum USER_ROLES {
  ADMIN = 'admin',
  MANAGER = 'manager',
  ATTENDEE = 'attendee',
}
export interface IDecodedUser {
  email: string;
  exp: number;
  firstName: string;
  iat: number;
  lastName: string;
  phone: string;
  role: USER_ROLES;
  userId: string;
}
