import { parseISO, format, isValid } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { iContract, IContractForDisplay } from 'interfaces/contracts';

const formatDate = (date: string) =>
  format(
    zonedTimeToUtc(parseISO(date), 'America/Sao_Paulo'),
    'dd/MM/yyyy HH:mm:ss',
  );

const formatShortDate = (date: string) =>
  format(zonedTimeToUtc(parseISO(date), 'America/Sao_Paulo'), 'dd/MM/yyyy');

export const dataTransform = ({
  Contracts,
}: {
  Contracts: iContract[];
}): iContract[] => {
  const result = Contracts.map((item: iContract, index: number) => {
    item.id = index;
    item.paymentMethods =
      item.paymentMethods === 0 ? 'transferencia' : 'cartão';
    item.createdAt = formatDate(item.createdAt);
    item.updatedAt = formatDate(item.updatedAt);
    item.contractComponents.forEach((component: IContractForDisplay, id) => {
      if (component.address) {
        (component.id = id + 1), (component.place = component.address.place);
        component.number = component.address.number;
        component.city = component.address.city;
        component.state = component.address.state;
        component.country = component.address.country;
        delete component.address;
        component.birthDate =
          component.birthDate !== undefined &&
          isValid(parseISO(component.birthDate))
            ? formatShortDate(component.birthDate)
            : '';
      }
    });
    return item;
  });
  return result;
};
