import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';
import { togleAlert } from 'modules/alertHandler';
import { userLogoutAction } from 'modules/User';
import { store, history, RootState } from 'store';

axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error
    if (error.response.status === 401) {
      store.dispatch(
        togleAlert({
          open: true,
          title: 'Sua Sessão Expirou',
          msg: 'Favor Logar Novamente',
          cb: () => store.dispatch(userLogoutAction({ history })),
        }),
      );
    }
    return Promise.reject(error);
  },
);

const axiosBaseOptions = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const state: RootState = store.getState();
  return {
    baseURL: `${process.env.REACT_APP_API_BASEURL}`,
    headers: { Authorization: `bearer ${state.user.token}` },
    ...config,
  };
};

const request = <T>(config: AxiosRequestConfig): AxiosPromise<T> =>
  axios.request<T>(axiosBaseOptions(config));

const get = <T>(config: AxiosRequestConfig): AxiosPromise<T> =>
  request({ ...config, method: 'GET' });

const post = <T>(config: AxiosRequestConfig): AxiosPromise<T> =>
  request({ ...config, method: 'POST' });

const put = <T>(config: AxiosRequestConfig): AxiosPromise<T> =>
  request({ ...config, method: 'PUT' });

const patch = <T>(config: AxiosRequestConfig): AxiosPromise<T> =>
  request({ ...config, method: 'PATCH' });

const del = <T>(config: AxiosRequestConfig): AxiosPromise<T> =>
  request({ ...config, method: 'DELETE' });

export { get, post, put, patch, del };
