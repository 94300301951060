import { ILeadInfoState, IleadPayload, ILeadResponse } from './interfaces';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REQUEST_LIFE_CICLE, IMyKnownError } from 'interfaces/requests';
import { AppDispatch } from 'store';
import endpoints from './endpoints';
import { post } from 'api';
import ReactGA from 'react-ga';
import { AxiosError } from 'axios';
import { setSecLevelAction } from 'modules/secLevel';

export const leadLoginAction = createAsyncThunk<
  ILeadResponse,
  IleadPayload,
  {
    dispatch: AppDispatch;
    rejectValue: IMyKnownError;
  }
>(
  'leadLogin/fullfiment',
  async (
    { LeadCode, setFieldError, setSubmitting, history, errMsg, errField },
    { dispatch, rejectWithValue },
  ) => {
    dispatch(clearLeadDataAction());
    try {
      const result = await post<ILeadResponse>({
        url: endpoints.login(LeadCode.trim()),
      });

      ReactGA.event({
        category: 'LeadLogin',
        action: LeadCode,
      });
      dispatch(setSecLevelAction(2));
      history.push('/home');
      return { nickname: result.data.nickname, leadCode: LeadCode };
    } catch (err) {
      const code = (err as AxiosError).response?.data?.code;
      setFieldError(errField, errMsg[code || 'incorrectCode']);
      ReactGA.exception({
        category: 'LeadLogin',
        action: LeadCode,
      });
      return rejectWithValue((await err.json()) as IMyKnownError);
    } finally {
      setSubmitting(false);
    }
  },
);

const initialState = {
  loading: REQUEST_LIFE_CICLE.IDLE,
  nickname: '',
  leadCode: '',
} as ILeadInfoState;

const LeadSlice = createSlice({
  name: 'LeadLogin',
  initialState,
  reducers: {
    clearLeadDataAction(state) {
      state = initialState;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(leadLoginAction.fulfilled, (state, { payload }) => {
      state.nickname = payload.nickname;
      state.leadCode = payload.leadCode;
      state.loading = REQUEST_LIFE_CICLE.SUCCEEDED;
      return state;
    });
    builder.addCase(leadLoginAction.pending, (state) => {
      state.loading = REQUEST_LIFE_CICLE.PENDING;
    });
    builder.addCase(leadLoginAction.rejected, (state) => {
      state.loading = REQUEST_LIFE_CICLE.IDLE;
    });
  },
});

const { reducer, actions } = LeadSlice;
export const { clearLeadDataAction } = actions;
export default reducer;
