import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { post } from 'api';
import { REQUEST_LIFE_CICLE, IMyKnownError } from 'interfaces/requests';
import { AppDispatch } from 'store';
import ReactGA from 'react-ga';
import { INewUserPayload, INewUserState } from './interfaces';
import endpoints from './endpoints';
import { togleAlert } from 'modules/alertHandler';

export const NewUserAction = createAsyncThunk<
  void,
  INewUserPayload,
  {
    dispatch: AppDispatch;
    rejectValue: IMyKnownError;
  }
>('newUserAction', async (payload, { dispatch, rejectWithValue }) => {
  payload.setSubmitting(true);
  try {
    await post({
      url: endpoints.newUser(),
      data: {
        role: payload.role,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        password: payload.password,
        phone: payload.phone,
      },
    });
    dispatch(
      togleAlert({
        open: true,
        title: 'Formulário enviado com sucesso',
        msg: `Usuário criado com sucesso`,
      }),
    );
    payload.resetForm();
    ReactGA.event({
      category: 'NewUserCreated',
      action: payload.email,
    });
  } catch (err) {
    dispatch(
      togleAlert({
        open: true,
        title: 'Lamentamos, mas algo deu errado.',
        msg: `Por favor, tente enviar novamente. Se o erro persistir,
              entre em contato com nossa equipe pelo e‑mail legado@rogatis.com.br.
              Estamos à disposição para ajudá-lo com isso.`,
        errMsg: err,
      }),
    );
    ReactGA.exception({
      category: 'NewUSerRegister',
      action: 'fail new User',
    });
    return rejectWithValue((await err.json()) as IMyKnownError);
  } finally {
    payload.setSubmitting(false);
  }
});

const initialState = {
  loading: REQUEST_LIFE_CICLE.IDLE,
} as INewUserState;

const NewUserSlice = createSlice({
  name: 'newUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(NewUserAction.pending, (state) => {
      state.loading = REQUEST_LIFE_CICLE.PENDING;
      return state;
    });
    builder.addCase(NewUserAction.rejected, (state) => {
      state.loading = REQUEST_LIFE_CICLE.FAILED;
      return state;
    });
  },
});

const { reducer } = NewUserSlice;
export default reducer;
