import { createSlice } from '@reduxjs/toolkit';
import { LEAD_FORM_NAV } from 'interfaces';

interface ISelectedLeadNavigation {
  mode: LEAD_FORM_NAV;
}

const initialState = {
  mode: LEAD_FORM_NAV.home,
} as ISelectedLeadNavigation;

const setSelectedLeadNavigationSlice = createSlice({
  name: 'setSelectedLeadNavigation',
  initialState,
  reducers: {
    setSelectedLeadNavigationAcion(
      state,
      { payload }: { payload: LEAD_FORM_NAV },
    ) {
      state.mode = payload;
      return state;
    },
  },
});
const { reducer, actions } = setSelectedLeadNavigationSlice;
export const { setSelectedLeadNavigationAcion } = actions;
export default reducer;
