/* eslint-disable @typescript-eslint/no-explicit-any */
import { capitalizeString } from './helpers';

const setSubProp = (key: string) => {
  const str = key.split('/')[2];
  return str && !str.includes('.') ? capitalizeString(str) : '';
};

const setPropName = (key: string) =>
  `${key.replace(/.ts/, '').split('/')[1]}${setSubProp(key)}`;

export const getFiles = (
  context: __WebpackModuleApi.RequireContext,
): { [s: string]: any } => {
  const keys = context.keys();
  const values = keys.map(context);
  return keys.reduce((acc, item, index) => {
    acc[item] = values[index];
    return acc;
  }, {} as { [s: string]: any });
};

export const mapFiles = (files: {
  [s: string]: { default: unknown };
}): { [s: string]: unknown } =>
  Object.entries(files).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [setPropName(key)]: value.default,
    }),
    {},
  );
