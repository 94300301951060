import { Overrides } from '@material-ui/core/styles/overrides';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as Labs from '@material-ui/lab/themeAugmentation/index';

const MuiAppBar: Overrides['MuiAutocomplete'] = {
  popupIndicator: {
    color: 'inherit',
  },
  inputRoot: {
    borderColor: 'inherit',
  },
};

export default MuiAppBar;
