import { Theme } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import themeOverrides from './overrides';
import paletteOverrides from './palette';

const theme: Theme = createMuiTheme({
  typography: {
    fontFamily: ['JosefinSans', 'openSans'].join(','),
  },
  palette: paletteOverrides,
  overrides: themeOverrides,
  props: {
    MuiTextField: {
      variant: 'outlined',
      margin: 'normal',
      fullWidth: true,
    },
    MuiSelect: {
      variant: 'outlined',
    },
    MuiOutlinedInput: {
      fullWidth: true,
    },
  },
});

export default theme;
