import JosefinSansRegular from 'assets/fonts/josefin-sans-regular.woff2';
import OpenSansRegular from 'assets/fonts/open-sans-regular.woff2';
import { Overrides } from '@material-ui/core/styles/overrides';

const josefinSansRegular = {
  fontFamily: 'josefinSans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('josefinSans'),
    local('josefinSans-Regular'),
    url(${JosefinSansRegular}) format('woff2')
  `,
};

const openSansRegular = {
  fontFamily: 'openSans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('openSans'),
    local('openSans-Regular'),
    url(${OpenSansRegular}) format('woff2')
  `,
};

const MuiCssBaseline: Overrides['MuiCssBaseline'] = {
  '@global': {
    '@font-face': [josefinSansRegular, openSansRegular],
    html: {
      height: '-webkit-fill-available',
      'overflow-x': 'hidden',
    },
    body: {
      minHeight: '-webkit-fill-available',
    },
  },
};

export default MuiCssBaseline;
