export interface ISecLevel {
  secLevel: number;
}

export enum SEC_LEVELS {
  NONE = 0,
  LEAD = 2,
  RM = 3,
  ADMIN = 4,
}
